<template>
    <div>
        <div>
            <el-page-header @back="$router.go(-1)" content="邮件模板"/>
        </div>
        <div style="margin-top: 20px">
            <el-table stripe :data="table.data" style="width: 100%;margin-top:10px" v-loading="loading" border
                      @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55" align="center"/>
                <el-table-column align="left"
                                 v-for="(v, idx) in table.columns"
                                 :prop="v.field"
                                 :label="v.title"
                                 :width="v.width"
                                 :formatter="v.formatter"
                                 :key="idx">
                </el-table-column>
                <el-table-column width="150" align="center" label="操作">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="handleMailTmpl(scope.row)"
                                   :loading="scope.row.loading">邮件设置
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="text-align: center;margin: 20px">
                <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[5, 10, 20, 30, 50, 100]"
                               :total="table.total" :page-size="table.pageSize" @current-change="handleCurrentChange"
                               @size-change="handleSizeChange"/>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                loading: false,
                table: {
                    columns: [
                        {title: '证书名称', field: 'title', width: ''},
                    ],
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 20
                },
                keywords: '',
                selected: [],
            }
        },
        methods: {
            handleCurrentChange(e) {
                this.table.page = e
                this.getData()
            },
            handleSizeChange(e) {
                this.table.pageSize = e
                this.table.page = 1
                this.getData()
            },
            async getData() {
                this.loading = true
                const resp = await this.$http.get(`/cert/?page=${this.table.page}&pageSize=${this.table.pageSize}&keywords=${this.keywords}`)
                if (resp.data.state != 200) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.table.data = resp.data.data.content || []
                    this.table.total = resp.data.data.totalElements || 0
                }
                this.loading = false
            },
            handleSelectionChange(val) {
                console.log(val)
                this.selected = val
            },
            handleMailTmpl(row) {
                this.$router.push(`/main/mail_tmpl/mail?cid=${row.id}`)
            },
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .pull-right {
        float: right
    }
</style>
